var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"86px"}},[_c('div',{staticClass:"my-2"},[_c('v-input',{staticClass:"caption-xs font-weight-regular",attrs:{"hide-details":!_vm.invalidCheckupItems,"error":_vm.invalidCheckupItems,"error-messages":_vm.invalidCheckupItemsErrorMsg}},[_c('div',{staticClass:"d-flex full-width"},[_c('label',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"px-1",staticStyle:{"pointer-events":"none"},attrs:{"translate-context":"MCheckupListEditor component content","for":"add-new-checkup-item"}},[_vm._v(" New check item: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkupItem),expression:"checkupItem"}],ref:"addNewCheckupItem",staticClass:"flex-grow-1 lighten-3 px-1 checkup-input",staticStyle:{"padding-top":"2px","padding-bottom":"2px"},attrs:{"id":"add-new-checkup-item","placeholder":_vm.newCheckupItemPlaceholder},domProps:{"value":(_vm.checkupItem)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addCheckupItem.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.checkupItem=$event.target.value}}}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addCheckupItem}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MCheckupListEditor Add Checkup item"}},[_vm._v(" Add ")])])],1)])],1),_c('v-input',{staticStyle:{"display":"none"},attrs:{"rules":_vm.validators.requiredArray},model:{value:(_vm.checkupItems),callback:function ($$v) {_vm.checkupItems=$$v},expression:"checkupItems"}}),(_vm.checkupItems.length > 0)?_c('v-list',{staticClass:"relative",attrs:{"subheader":"","dense":"","height":_vm.HEIGHT * _vm.checkupItems.length}},_vm._l((_vm.checkupItems),function(checkupItem,i){
var _obj;
return _c('v-list-item',{key:i,ref:("checkup-item-" + (checkupItem.id)),refInFor:true,staticClass:"draggable full-width px-0",class:( _obj = {}, _obj[_vm.TARGET_CLASS] = true, _obj ),style:({
        minHeight: 0,
        position: 'absolute',
        top: ((i * _vm.HEIGHT) + "px"),
        right: 0,
        left: 0,
      }),attrs:{"data-order":i}},[_c('v-list-item-action',{staticClass:"drag-handle ma-0 pr-3",style:({
          opacity: checkupItem._destroy ? 0.5 : 1,
          pointerEvents: checkupItem._destroy ? 'none' : 'initial',
        })},[_c('v-icon',[_vm._v(" drag_handle ")])],1),_c('v-list-item-content',{staticClass:"py-1",style:({
          opacity: checkupItem._destroy ? 0.5 : 1,
        })},[_c('v-list-item-title',{class:{
            done: false
          }},[_c('v-input',{staticClass:"caption-xs font-weight-regular",attrs:{"hide-details":"","disabled":checkupItem._destroy}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(checkupItem.title),expression:"checkupItem.title"}],staticClass:"full-width lighten-3 px-1 checkup-input",staticStyle:{"padding-top":"2px","padding-bottom":"2px"},domProps:{"value":(checkupItem.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(checkupItem, "title", $event.target.value)}}})])],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":checkupItem._destroy ? 'secondary' : 'error'},on:{"click":function (e) { return _vm.removeCheckupItem(e, i); }}},[_c('v-icon',[_vm._v(" "+_vm._s(checkupItem._destroy ? 'undo' : 'close')+" ")])],1)],1)]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.removeRestoreCheckupRowTooltip(checkupItem))+" ")])])],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }