<template>
  <div>
    <v-form
      :data-vv-scope="scope"
      @submit.prevent="onSave"
    >
      <v-card
        tile
      >
        <v-btn
          v-if="!isNew"
          :loading="destroying"
          :disabled="saving || destroying"
          text
          small
          tile
          color="error"
          style="position: absolute; right: 0; top: 0;"
          @click="_onDestroy"
        >
          {{ removeMessage }}
        </v-btn>

        <v-card-title
          class="font-weight-bold backgroundAccent lighten-3 pl-8"
        >
          {{ title }}
        </v-card-title>

        <v-card-text class="pa-8">
          <slot />
        </v-card-text>

        <v-card-actions
          class="backgroundAccent lighten-3 px-8 py-4"
        >
          <v-spacer />

          <v-btn
            :disabled="saving || destroying"
            text
            color="error"
            @click="closeSidePanel"
          >
            <span v-translate>
              Cancel
            </span>
          </v-btn>

          <v-btn
            :loading="saving"
            :disabled="saving || destroying"
            color="success"
            type="submit"
          >
            <span v-translate>
              Save
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <slot name="footer" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    props: {
      scope: {
        type: String,
        required: true,
      },
      onSave: {
        type: Function,
        required: true,
      },
      onDestroy: {
        type: Function,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      saving: {
        type: Boolean,
        required: true,
      },
      destroying: {
        type: Boolean,
        required: true,
      },
      isNew: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      removeConfirm: false,
    }),

    computed: {
      removeMessage() {
        if (this.removeConfirm) {
          return this.$pgettext('MSidebarCard component delete button confirm', 'Sure?');
        }

        return this.$pgettext('MSidebarCard component delete button', 'Delete');
      },
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      _onDestroy() {
        if (!this.removeConfirm) {
          this.removeConfirm = true;
          return;
        }

        this.onDestroy();
      },
    },
  };
</script>
