<template>
  <div
    style="min-height: 86px;"
  >
    <!-- add new input -->
    <div
      class="my-2"
    >
      <v-input
        class="caption-xs font-weight-regular"
        :hide-details="!invalidCheckupItems"
        :error="invalidCheckupItems"
        :error-messages="invalidCheckupItemsErrorMsg"
      >
        <div
          class="d-flex full-width"
        >
          <label
            v-translate
            translate-context="MCheckupListEditor component content"
            for="add-new-checkup-item"
            style="pointer-events: none;"
            class="px-1"
          >
            New check item:
          </label>
          
          <input
            id="add-new-checkup-item"
            ref="addNewCheckupItem"
            v-model="checkupItem"
            class="flex-grow-1 lighten-3 px-1 checkup-input"
            style="padding-top: 2px; padding-bottom: 2px;"
            :placeholder="newCheckupItemPlaceholder"
            @keydown.enter="addCheckupItem"
          >
          <v-btn
            color="primary"
            text
            @click="addCheckupItem"
          >
            <span
              v-translate
              translate-context="MCheckupListEditor Add Checkup item"
            >
              Add
            </span>
          </v-btn>
        </div>
      </v-input>
    </div>

    <!-- Hidden input to validate the tmpCheckup.items array -->
    <v-input
      v-model="checkupItems"
      :rules="validators.requiredArray"
      style="display: none;"
    />

    <v-list
      v-if="checkupItems.length > 0"
      subheader
      dense
      class="relative"
      :height="HEIGHT * checkupItems.length"
    >
      <v-list-item
        v-for="(checkupItem, i) in checkupItems"
        :ref="`checkup-item-${checkupItem.id}`"
        :key="i"
        class="draggable full-width px-0"
        :class="{
          [TARGET_CLASS]: true,
        }"
        :data-order="i"
        :style="{
          minHeight: 0,
          position: 'absolute',
          top: `${i * HEIGHT}px`,
          right: 0,
          left: 0,
        }"
      >
        <v-list-item-action
          class="drag-handle ma-0 pr-3"
          :style="{
            opacity: checkupItem._destroy ? 0.5 : 1,
            pointerEvents: checkupItem._destroy ? 'none' : 'initial',
          }"
        >
          <v-icon>
            drag_handle
          </v-icon>
        </v-list-item-action>

        <v-list-item-content
          class="py-1"
          :style="{
            opacity: checkupItem._destroy ? 0.5 : 1,
          }"
        >
          <v-list-item-title
            :class="{
              done: false
            }"
          >
            <v-input
              class="caption-xs font-weight-regular"
              hide-details
              :disabled="checkupItem._destroy"
            >
              <input
                v-model="checkupItem.title"
                class="full-width lighten-3 px-1 checkup-input"
                style="padding-top: 2px; padding-bottom: 2px;"
              >
            </v-input>
          </v-list-item-title>
        </v-list-item-content>

        <v-tooltip
          top
        >
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-btn
                icon
                x-small
                class="ml-1"
                :color="checkupItem._destroy ? 'secondary' : 'error' "
                @click="e => removeCheckupItem(e, i)"
              >
                <v-icon>
                  {{ checkupItem._destroy ? 'undo' : 'close' }}
                </v-icon>
              </v-btn>
            </div>
          </template>

          <div>
            {{ removeRestoreCheckupRowTooltip(checkupItem) }}
          </div>
        </v-tooltip>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  import interact from 'interactjs';
  import getValidators from '@/mixins/validators';

  export default {
    props: {
      checkupItems: {
        type: Array,
        required: true,
      },

      formValid: {
        type: Boolean,
        default: true,
      },

      softRemove: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      ...getValidators('requiredArray'),
      checkupItem: '',

      WIDTH: 300,
      HEIGHT: 28,
      TARGET_CLASS: 'checkup-item',
    }),

    computed: {
      invalidCheckupItems() {
        return !this.formValid && this.checkupItems.length <= 0;
      },

      invalidCheckupItemsErrorMsg() {
        if (!this.invalidCheckupItems) {
          return '';
        }

        return this.$pgettext('MCheckupListEditor component content', 'Add at least one checkup');
      },

      newCheckupItemPlaceholder() {
        return this.$pgettext('MCheckupListEditor component new checkup item placeholder', 'e.g. floor slopes done');
      },
    },

    mounted() {
      this.initCheckupItemDrag();

      this.$nextTick(() => {
        this.$refs.addNewCheckupItem.focus();
      });
    },

    methods: {
      addCheckupItem(e) {
        e.preventDefault();

        this.$mixpanel.trackEvent('Add Checkup Item v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });

        if (this.checkupItem.length <= 0) return;

        const item = {
          title: this.checkupItem,
          order: this.checkupItems.length,
        };

        this.checkupItems.push(item);
        this.checkupItem = '';
        this.recalculateCheckupItemOrders();
      },

      removeCheckupItem(e, i) {
        e.preventDefault();

        this.$mixpanel.trackEvent('Remove Checkup Item v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });

        if (this.softRemove) {
          const checkupItem = {
            ...this.checkupItems[i],
            _destroy: !this.checkupItems[i]._destroy,
          };
          this.checkupItems.splice(i, 1, checkupItem);
        } else {
          this.checkupItems.splice(i, 1);
        }

        this.recalculateCheckupItemOrders();
      },

      /**
       * We need to re-calculate the order in case something was deleted from the
       * middle of the list, and potentially when new items have been appended.
       */
      recalculateCheckupItemOrders() {
        this
          .checkupItems
          .filter(item => !item._destroy)
          .forEach((item, order) => item.order = order); // eslint-disable-line
      },

      initCheckupItemDrag() {
        const klass = `.${this.TARGET_CLASS}`;

        interact(klass).unset();

        interact(klass)
          .draggable({
            allowFrom: '.drag-handle',
            lockAxis: 'y', // Allow dragging only on Y axis
            modifiers: [
              interact.modifiers.snap({
                targets: [
                  interact.createSnapGrid({ x: this.WIDTH, y: this.HEIGHT }),
                ],
                range: Infinity,
                offset: 'startCoords',
              }),
              interact.modifiers.restrict({
                restriction: 'parent',
                elementRect: {
                  top: 0, left: 0, bottom: 0, right: 0,
                },
              }),
            ],
            listeners: {
              move: (event) => {
                const {
                  dy,
                  target: draggedTarget,
                } = event;

                const order = parseFloat(draggedTarget.dataset.order);
                const newOrder = order + (dy / this.HEIGHT);

                const draggedEl = document.querySelector(`.checkup-item[data-order="${order}"]`);
                const affectedEl = document.querySelector(`.checkup-item[data-order="${newOrder}"]`);

                /**
                 * Handles out of bounds.
                 */
                if (!draggedEl || !affectedEl) return;

                const draggedTop = parseInt(draggedEl.style.top.replace('px', ''), 10);
                const affectedTop = parseInt(affectedEl.style.top.replace('px', ''), 10);

                Object.assign(draggedEl.style, {
                  top: `${affectedTop}px`,
                });

                Object.assign(affectedEl.style, {
                  top: `${draggedTop}px`,
                });

                draggedEl.setAttribute('data-order', `${newOrder}`);
                affectedEl.setAttribute('data-order', `${order}`);

                /**
                 * Re-order the actual list
                 */
                const sourceCheckupItem = this.checkupItems[order];
                this.checkupItems[order] = this.checkupItems[newOrder];
                this.checkupItems[newOrder] = sourceCheckupItem;

                this.$mixpanel.trackEvent('Drag Checkup Item v1', {
                  projectId: this.$projectId,
                  userId: this.$currentUser.id,
                });
              },
            },
          });
      },

      removeRestoreCheckupRowTooltip(checkupItem) {
        if (checkupItem._destroy) {
          return this.$pgettext('MCheckupListEditor component content', 'Restore row');
        }

        return this.$pgettext('MCheckupListEditor component content', 'Delete row');
      },
    },
  };
</script>
