var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{attrs:{"max-width":"400px"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"label":_vm.searchPlaceholder,"single-line":"","hide-details":"","outlined":"","dense":"","autofocus":"","clearable":""},on:{"click:clear":function () { return _vm.search = ''; }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.checkupTemplates,"search":_vm.search,"dense":"","single-select":"","hide-default-footer":"","items-per-page":-1},on:{"item-selected":_vm.onRowSelect},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('div',{staticClass:"relative"},[_c('v-btn',{attrs:{"dark":"","fab":"","small":"","absolute":"","right":"","top":"","color":"info"},on:{"click":function () { return _vm.editCheckup(); }}},[_c('v-icon',[_vm._v(" add ")])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('tr',{class:{
              'info--text': isSelected,
            },style:({
              backgroundColor: isSelected ? '#DFE9F5' : '',
              cursor: 'pointer',
            }),on:{"click":function($event){return select(!isSelected)}}},[_c('td',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showCheckupCard)?_c('m-checkup-card',{attrs:{"checkup":_vm.selectedCheckup,"on-cancel":_vm.onCancel,"on-submit":_vm.onSubmit}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }