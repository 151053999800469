var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('m-sidebar-card',{attrs:{"scope":_vm.scope,"on-save":_vm.save,"on-destroy":_vm.destroy,"saving":_vm.$wait.is(['saving task', 'saving checkup']),"destroying":_vm.$wait.is(['destroying task', 'destroying checkup']),"is-new":_vm.isNew,"title":_vm.title},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('m-btn-help',{staticClass:"mt-3",style:({
        right: '24px',
      }),attrs:{"absolute":""},on:{"click":function () { return _vm.startTour('MTaskEditor'); }}})]},proxy:true}])},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: true,
    }),expression:"{\n      required: true,\n    }"}],ref:"taskNameRef",staticClass:"mb-4",attrs:{"error-messages":_vm.errors.collect('name', _vm.scope),"data-vv-validate-on":"blur","name":"task.name","label":_vm.taskNamePlaceholder,"outlined":""},model:{value:(_vm.task.name),callback:function ($$v) {_vm.$set(_vm.task, "name", $$v)},expression:"task.name"}}),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: false,
    }),expression:"{\n      required: false,\n    }"}],ref:"taskDescriptionRef",staticClass:"mb-4",attrs:{"error-messages":_vm.errors.collect('name', _vm.scope),"data-vv-validate-on":"blur","name":"task.description","label":_vm.taskDescriptionPlaceholder,"outlined":""},model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: true,
      min_value: 0,
    }),expression:"{\n      required: true,\n      min_value: 0,\n    }"}],ref:"estimatedDurationRef",staticClass:"mb-4",attrs:{"error-messages":_vm.errors.collect('estimatedDuration', _vm.scope),"data-vv-validate-on":"blur","name":"task.estimatedDuration","label":_vm.taskEstimatedDurationPlaceholder,"suffix":_vm.durationSuffix,"type":"number","outlined":""},model:{value:(_vm.estimatedDuration),callback:function ($$v) {_vm.estimatedDuration=$$v},expression:"estimatedDuration"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: true,
      min_value: 0,
    }),expression:"{\n      required: true,\n      min_value: 0,\n    }"}],ref:"waitingDurationRef",staticClass:"mb-4",attrs:{"error-messages":_vm.errors.collect('waitingDuration', _vm.scope),"data-vv-validate-on":"blur","name":"task.waitingDuration","label":_vm.taskWaitingDurationPlaceholder,"suffix":_vm.durationSuffix,"type":"number","outlined":"","hide-details":""},model:{value:(_vm.waitingDuration),callback:function ($$v) {_vm.waitingDuration=$$v},expression:"waitingDuration"}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MTaskEditor component sub header"}},[_vm._v(" Task color ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}])},[_c('span',{directives:[{name:"translate",rawName:"v-translate",value:({ br: '<br>' }),expression:"{ br: '<br>' }"}],attrs:{"translate-context":"MTaskEditor component tooltip","render-html":"true"}},[_vm._v(" You can define a set of colors for your project %{ br } that you can use to group tasks. %{ br }%{ br } 1. Create colors of your liking %{ br } 2. Click a row to choose a color ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","depressed":""},on:{"click":_vm.startColorOptionEditing}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MTaskEditor component button"}},[_vm._v(" New color ")])])],1),_c('v-simple-table',{staticClass:"elevation-1 mb-10",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.project.colors.length <= 0)?_c('tr',[_c('td',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption font-italic",attrs:{"translate-context":"MTaskEditor component content"}},[_vm._v(" Create colors by clicking \"New color\" ")])]):_vm._e(),_vm._l((_vm.project.colors),function(color,i){return _c('tr',{key:i,staticStyle:{"cursor":"pointer"},on:{"click":function () { return _vm.onColorChange(color); }}},[_c('td',{class:{
              'backgroundAccent lighten-2': _vm.task.hex === color.hex,
            }},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"small":"","color":"success"}},[(_vm.task.hex === color.hex)?[_vm._v(" radio_button_checked ")]:[_vm._v(" radio_button_unchecked ")]],2),_c('div',{staticClass:"mx-4",staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(color.name)+" ")]),_c('div',[_c('div',{style:({
                      background: color.hex,
                      width: '40px',
                      height: '20px',
                      borderRadius: '2px',
                    })})]),_c('v-spacer'),_c('div',[_c('v-btn',{style:({
                      opacity: hover ? 1 : 0,
                    }),attrs:{"disabled":_vm.$wait.is('updating project'),"loading":_vm.$wait.is(("updating-color-options-" + (color.hex))),"icon":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.destroyColorOption(color); }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)],1)],1)]}}],null,true)})],1)])}),(_vm.editingColorOptions)?_c('tr',[_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-text-field',{ref:"colorInputRef",staticClass:"py-2 ma-0",staticStyle:{"max-width":"124px"},attrs:{"disabled":_vm.$wait.is('updating project'),"placeholder":_vm.$pgettext('MTaskEditor color input placeholder', 'Color name'),"hide-details":"","dense":""},model:{value:(_vm.newColorOption.name),callback:function ($$v) {_vm.$set(_vm.newColorOption, "name", $$v)},expression:"newColorOption.name"}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.$wait.is('updating project'),"x-small":"","depressed":"","text":!_vm.newColorOption.hex,"dark":!!_vm.newColorOption.hex && _vm.newColorOption.hex !== _vm.DEFAULT_COLOR,"color":_vm.newColorOption.hex}},'v-btn',attrs,false),on),[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MTaskEditor component button"}},[_vm._v(" Color ")]),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" keyboard_arrow_down ")])],1)]}}],null,false,4153837382),model:{value:(_vm.colorMenu),callback:function ($$v) {_vm.colorMenu=$$v},expression:"colorMenu"}},[_c('v-card',{staticClass:"overflow-hidden",attrs:{"max-width":"200"}},[_c('v-item-group',{on:{"change":_vm.onColorOptionChange}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.colorOptions),function(color,i){return _c('v-col',{key:i},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"color":color,"dark":"","tile":"","flat":"","width":"50px","height":"50px"},on:{"click":toggle}},[_c('v-scroll-y-transition',[(active)?_c('v-icon',{attrs:{"color":color === _vm.DEFAULT_COLOR ? 'black' : 'white'}},[_vm._v(" check ")]):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"disabled":_vm.$wait.is('updating project'),"small":"","icon":""},on:{"click":_vm.resetColorOption}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" clear ")])],1),_c('v-btn',{attrs:{"disabled":_vm.$wait.is('updating project')
                    || _vm.newColorOption.name.length <= 0 || _vm.newColorOption.hex.length <= 0,"loading":_vm.$wait.is('updating project'),"small":"","icon":""},on:{"click":_vm.createColorOption}},[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" save ")])],1)],1)])])]):_vm._e()],2)]},proxy:true}])}),_c('v-checkbox',{ref:"requiresSupervisionRef",staticClass:"mt-0 pt-0",attrs:{"label":_vm.requiresSupervisionLabel,"hint":_vm.requiresSupervisionHint,"persistent-hint":"","color":"warning"},model:{value:(_vm.task.requiresSupervision),callback:function ($$v) {_vm.$set(_vm.task, "requiresSupervision", $$v)},expression:"task.requiresSupervision"}}),(_vm.$currentUser.enabledFeatures.checkups)?_c('div',{staticClass:"relative"},[_c('v-checkbox',{attrs:{"label":_vm.useCheckupsLabel,"hint":_vm.useCheckupsHint,"persistent-hint":"","color":"info"},model:{value:(_vm.showCheckupEditor),callback:function ($$v) {_vm.showCheckupEditor=$$v},expression:"showCheckupEditor"}}),(_vm.showCheckupEditor)?_c('m-checkup-container',{attrs:{"task":_vm.task}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }