<template>
  <v-form
    ref="form"
    v-model="formValid"
    lazy-validation
    @submit.prevent="submit"
  >
    <v-card>
      <v-card-title
        class="backgroundAccent lighten-1 subtitle-2"
      >
        {{ checkupListTitle }}
      </v-card-title>

      <v-card-text
        class="pt-4"
      >
        <v-text-field
          v-model="tmpCheckup.name"
          :label="checkupListNamePlaceholder"
          filled
          dense
          hide-details
          :rules="[validators.required]"
          validate-on-blur
          class="mb-5"
          autofocus
        />

        <m-checkup-list-editor
          :checkup-items="tmpCheckup.items"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <div
          v-if="!isNew"
          style="min-width: 80px"
        >
          <v-btn
            :disabled="$wait.is(['saving template', 'destroying template'])"
            :loading="$wait.is('destroying template')"
            class="px-2 py-1"
            color="error"
            small
            text
            min-width="0"
            height="auto"
            @click="destroy"
          >
            <div
              v-if="confirmDestroy"
              v-translate
              translate-context="MCheckupCard component delete button confirm"
            >
              Sure?
            </div>

            <v-icon
              v-else
              x-small
            >
              delete
            </v-icon>
          </v-btn>
        </div>

        <v-spacer />

        <v-btn
          :disabled="$wait.is(['saving template', 'destroying template'])"
          text
          small
          color="error"
          @click="onCancel"
        >
          <span v-translate>
            Cancel
          </span>
        </v-btn>

        <v-btn
          :disabled="$wait.is(['saving template', 'destroying template'])"
          :loading="$wait.is(['saving template'])"
          :dark="!$wait.is(['saving template'])"
          small
          color="secondary"
          class="px-4"
          type="submit"
        >
          <span v-translate>
            Save
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import getValidators from '@/mixins/validators';
  import MCheckupListEditor from '@/components/checkups/MCheckupListEditor';

  export default {
    components: {
      MCheckupListEditor,
    },

    props: {
      checkup: {
        type: Object,
        required: true,
      },

      onCancel: {
        type: Function,
        default: () => {},
      },

      onSubmit: {
        type: Function,
        default: () => {},
      },
    },

    data: () => ({
      tmpCheckup: null,
      formValid: true,
      confirmDestroy: false,
      ...getValidators('required'),
    }),

    computed: {
      isNew() {
        return !this.checkup.id;
      },

      checkupListTitle() {
        if (this.isNew) {
          return this.$pgettext('MCheckupCard component content', 'Create checkup list');
        }

        return this.$pgettext('MCheckupCard component content', 'Modify checkup list');
      },

      checkupListNamePlaceholder() {
        return this.$pgettext('Checkup list name placeholder', 'Checkup list name');
      },
    },

    watch: {
      checkup: {
        immediate: true,
        handler(checkup, prevCheckup) {
          if (checkup === prevCheckup) return;
          this.tmpCheckup = cloneDeep(checkup);
        },
      },
    },

    methods: {
      ...mapWaitingActions('project/checkupTemplates', {
        createCheckupTemplate: 'saving template',
        updateCheckupTemplate: 'saving template',
        destroyCheckupTemplate: 'destroying template',
      }),

      async submit() {
        await this.$refs.form.validate();

        if (!this.formValid) return;

        if (this.isNew) {
          await this.createCheckupTemplate({
            checkupTemplate: this.tmpCheckup,
          });

          this.$mixpanel.trackEvent('Create Checkup Template v1', {
            projectId: this.$projectId,
            userId: this.$currentUser.id,
          });
        } else {
          await this.updateCheckupTemplate({
            id: this.tmpCheckup.id,
            payload: {
              checkupTemplate: this.tmpCheckup,
            },
          });

          this.$mixpanel.trackEvent('Update Checkup Template v1', {
            projectId: this.$projectId,
            userId: this.$currentUser.id,
          });
        }

        this.onSubmit();
      },

      async destroy() {
        if (this.confirmDestroy) {
          await this.destroyCheckupTemplate({ id: this.tmpCheckup.id });
          this.onCancel();

          this.$mixpanel.trackEvent('Destroy Checkup Template v1', {
            projectId: this.$projectId,
            userId: this.$currentUser.id,
          });
        } else {
          this.confirmDestroy = true;
        }
      },
    },
  };
</script>

<style lang="scss">
.checkup-input {
  background: var(--v-white-darken1);

  &:hover {
    background: var(--v-white-darken2);
  }
}
</style>
