<template>
  <m-sidebar-card
    :scope="scope"
    :on-save="save"
    :on-destroy="destroy"
    :saving="$wait.is(['saving task', 'saving checkup'])"
    :destroying="$wait.is(['destroying task', 'destroying checkup'])"
    :is-new="isNew"
    :title="title"
  >
    <v-text-field
      ref="taskNameRef"
      v-model="task.name"
      v-validate="{
        required: true,
      }"
      :error-messages="errors.collect('name', scope)"
      data-vv-validate-on="blur"
      class="mb-4"
      name="task.name"
      :label="taskNamePlaceholder"
      outlined
    />
    <v-textarea
      ref="taskDescriptionRef"
      v-model="task.description"
      v-validate="{
        required: false,
      }"
      :error-messages="errors.collect('name', scope)"
      data-vv-validate-on="blur"
      class="mb-4"
      name="task.description"
      :label="taskDescriptionPlaceholder"
      outlined
    />
    <v-text-field
      ref="estimatedDurationRef"
      v-model="estimatedDuration"
      v-validate="{
        required: true,
        min_value: 0,
      }"
      :error-messages="errors.collect('estimatedDuration', scope)"
      data-vv-validate-on="blur"
      class="mb-4"
      name="task.estimatedDuration"
      :label="taskEstimatedDurationPlaceholder"
      :suffix="durationSuffix"
      type="number"
      outlined
    />

    <v-text-field
      ref="waitingDurationRef"
      v-model="waitingDuration"
      v-validate="{
        required: true,
        min_value: 0,
      }"
      :error-messages="errors.collect('waitingDuration', scope)"
      data-vv-validate-on="blur"
      class="mb-4"
      name="task.waitingDuration"
      :label="taskWaitingDurationPlaceholder"
      :suffix="durationSuffix"
      type="number"
      outlined
      hide-details
    />

    <div
      class="d-flex align-center"
    >
      <v-subheader
        v-translate
        translate-context="MTaskEditor component sub header"
      >
        Task color
      </v-subheader>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            v-bind="attrs"
            v-on="on"
          >
            info
          </v-icon>
        </template>

        <span
          v-translate="{ br: '<br>' }"
          translate-context="MTaskEditor component tooltip"
          render-html="true"
        >
          You can define a set of colors for your project
          %{ br }
          that you can use to group tasks.
          %{ br }%{ br }
          1. Create colors of your liking
          %{ br }
          2. Click a row to choose a color
        </span>
      </v-tooltip>

      <v-spacer />

      <v-btn
        x-small
        depressed
        @click="startColorOptionEditing"
      >
        <span
          v-translate
          translate-context="MTaskEditor component button"
        >
          New color
        </span>
      </v-btn>
    </div>

    <v-simple-table
      dense
      class="elevation-1 mb-10"
    >
      <template v-slot:default>
        <tbody>
          <tr
            v-if="project.colors.length <= 0"
          >
            <td
              v-translate
              translate-context="MTaskEditor component content"
              class="caption font-italic"
            >
              Create colors by clicking "New color"
            </td>
          </tr>

          <tr
            v-for="(color, i) in project.colors"
            :key="i"
            style="cursor: pointer;"
            @click="() => onColorChange(color)"
          >
            <td
              :class="{
                'backgroundAccent lighten-2': task.hex === color.hex,
              }"
            >
              <v-hover
                v-slot:default="{ hover }"
              >
                <div
                  class="d-flex align-center"
                >
                  <v-icon
                    small
                    color="success"
                  >
                    <template
                      v-if="task.hex === color.hex"
                    >
                      radio_button_checked
                    </template>

                    <template
                      v-else
                    >
                      radio_button_unchecked
                    </template>
                  </v-icon>

                  <div
                    style="width: 100px;"
                    class="mx-4"
                  >
                    {{ color.name }}
                  </div>

                  <div>
                    <div
                      :style="{
                        background: color.hex,
                        width: '40px',
                        height: '20px',
                        borderRadius: '2px',
                      }"
                    />
                  </div>

                  <v-spacer />

                  <div>
                    <v-btn
                      :disabled="$wait.is('updating project')"
                      :loading="$wait.is(`updating-color-options-${color.hex}`)"
                      icon
                      small
                      color="error"
                      :style="{
                        opacity: hover ? 1 : 0,
                      }"
                      @click.stop="() => destroyColorOption(color)"
                    >
                      <v-icon
                        small
                      >
                        delete
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-hover>
            </td>
          </tr>

          <tr
            v-if="editingColorOptions"
          >
            <td>
              <div
                class="d-flex align-center"
              >
                <div
                  class="flex-grow-1"
                >
                  <v-text-field
                    ref="colorInputRef"
                    v-model="newColorOption.name"
                    :disabled="$wait.is('updating project')"
                    :placeholder="$pgettext('MTaskEditor color input placeholder', 'Color name')"
                    hide-details
                    dense
                    class="py-2 ma-0"
                    style="max-width: 124px;"
                  />
                </div>

                <div
                  class="flex-grow-1"
                >
                  <v-menu
                    v-model="colorMenu"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="$wait.is('updating project')"
                        x-small
                        depressed
                        :text="!newColorOption.hex"
                        :dark="!!newColorOption.hex && newColorOption.hex !== DEFAULT_COLOR"
                        :color="newColorOption.hex"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span
                          v-translate
                          translate-context="MTaskEditor component button"
                        >
                          Color
                        </span>

                        <v-icon
                          small
                          right
                        >
                          keyboard_arrow_down
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card
                      max-width="200"
                      class="overflow-hidden"
                    >
                      <v-item-group
                        @change="onColorOptionChange"
                      >
                        <v-row
                          no-gutters
                        >
                          <v-col
                            v-for="(color, i) in colorOptions"
                            :key="i"
                          >
                            <v-item v-slot:default="{ active, toggle }">
                              <v-card
                                :color="color"
                                class="d-flex align-center justify-center"
                                dark
                                tile
                                flat
                                width="50px"
                                height="50px"
                                @click="toggle"
                              >
                                <v-scroll-y-transition>
                                  <v-icon
                                    v-if="active"
                                    :color="color === DEFAULT_COLOR ? 'black' : 'white'"
                                  >
                                    check
                                  </v-icon>
                                </v-scroll-y-transition>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-card>
                  </v-menu>
                </div>

                <div
                  class="d-flex"
                >
                  <v-btn
                    :disabled="$wait.is('updating project')"
                    small
                    icon
                    @click="resetColorOption"
                  >
                    <v-icon
                      small
                      color="error"
                    >
                      clear
                    </v-icon>
                  </v-btn>

                  <v-btn
                    :disabled="$wait.is('updating project')
                      || newColorOption.name.length <= 0 || newColorOption.hex.length <= 0"
                    :loading="$wait.is('updating project')"
                    small
                    icon
                    @click="createColorOption"
                  >
                    <v-icon
                      small
                      color="success"
                    >
                      save
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-checkbox
      ref="requiresSupervisionRef"
      v-model="task.requiresSupervision"
      :label="requiresSupervisionLabel"
      class="mt-0 pt-0"
      :hint="requiresSupervisionHint"
      persistent-hint
      color="warning"
    />

    <div
      v-if="$currentUser.enabledFeatures.checkups"
      class="relative"
    >
      <v-checkbox
        v-model="showCheckupEditor"
        :label="useCheckupsLabel"
        :hint="useCheckupsHint"
        persistent-hint
        color="info"
      />

      <m-checkup-container
        v-if="showCheckupEditor"
        :task="task"
      />
    </div>

    <template v-slot:footer>
      <m-btn-help
        absolute
        :style="{
          right: '24px',
        }"
        class="mt-3"
        @click="() => startTour('MTaskEditor')"
      />
    </template>
  </m-sidebar-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import colors from 'vuetify/lib/util/colors';
  import MSidebarCard from './MSidebarCard';
  import { tour } from '@/mixins/tour';
  import MCheckupContainer from '@/components/checkups/MCheckupContainer';

  export default {
    $_veeValidate: { validator: 'new' },

    components: {
      MCheckupContainer,
      MSidebarCard,
    },

    mixins: [
      tour,
    ],

    data: () => ({
      DEFAULT_COLOR: '#FFFFFF',
      editingColorOptions: false,
      showCheckupEditor: false,
      task: {
        name: '',
        estimatedDuration: 0,
        waitingDuration: 0,
        requiresSupervision: false,
        hex: null,
        checkupItems: [],
      },
      scope: 'task',
      colorMenu: false,
      newColorOption: {
        name: '',
        hex: '',
      },
    }),

    computed: {
      ...mapGetters({
        props: 'sidePanel/props',
        taskById: 'projectEditor/tasks/taskById',
        project: 'project/project/project',
      }),

      colorOptions() {
        const usedHex = this.project.colors.map(c => c.hex);

        const colorOptions = Object.keys(colors).reduce((acc, colorKey) => {
          const hex = colors[colorKey].base;
          if (hex && !usedHex.includes(hex)) acc.push(hex);
          return acc;
        }, []);

        const black = '#000000';
        if (!usedHex.includes(black)) colorOptions.push(black);

        return colorOptions;
      },

      taskId() {
        return this.props.taskId;
      },

      workPackageId() {
        return this.props.workPackageId;
      },

      teamId() {
        return this.props.teamId;
      },

      order() {
        return this.props.order;
      },

      x() {
        return this.props.x;
      },

      y() {
        return this.props.y;
      },

      isNew() {
        return this.taskId === null;
      },

      title() {
        if (this.isNew) {
          return this.$pgettext('MtaskEditor component title', 'New task');
        }

        return this.$pgettext('MtaskEditor component title', 'Edit task');
      },

      estimatedDuration: {
        get() {
          return this.task.estimatedDuration / 60;
        },

        set(estimatedDuration) {
          this.task.estimatedDuration = estimatedDuration * 60;
        },
      },

      waitingDuration: {
        get() {
          return this.task.waitingDuration / 60;
        },

        set(waitingDuration) {
          this.task.waitingDuration = waitingDuration * 60;
        },
      },

      taskNamePlaceholder() {
        return this.$pgettext('MtaskEditor component text input placeholder', 'Task name');
      },
  
      taskDescriptionPlaceholder() {
        return this.$pgettext('MtaskEditor component text input placeholder', 'Task description for workers');
      },

      taskEstimatedDurationPlaceholder() {
        return this.$pgettext('MtaskEditor component number input placeholder', 'Estimated duration');
      },

      taskWaitingDurationPlaceholder() {
        return this.$pgettext('MtaskEditor component number input placeholder', 'Waiting duration');
      },

      durationSuffix() {
        return this.$pgettext('MtaskEditor component number input suffix', 'hours');
      },

      requiresSupervisionLabel() {
        return this.$pgettext('MtaskEditor component checkbox input label', 'Pace-setting task');
      },

      requiresSupervisionHint() {
        return this.$pgettext('MtaskEditor component checkbox input hint', 'This task has to be completed before following tasks can be started.');
      },

      useCheckupsLabel() {
        return this.$pgettext('MtaskEditor component checkbox input label', 'Use checkup list (BETA)');
      },

      useCheckupsHint() {
        return this.$pgettext('MtaskEditor component checkbox input hint', 'Worker has to check up all items in the checkup list after completing this task.');
      },
    },

    watch: {
      /**
       * Resets checkupItems to initial state
       * when checkup option is unchecked.
       *
       * @param show
       */
      showCheckupEditor(show) {
        if (show) {
          this.$mixpanel.trackEvent('Enable Checkups v1', {
            projectId: this.$projectId,
            userId: this.$currentUser.id,
          });

          return;
        }

        if (this.isNew) {
          this.task.checkupItems = [];
        } else {
          this.task.checkupItems = [
            ...this.taskById(this.taskId).checkupItems,
          ];
        }

        this.$mixpanel.trackEvent('Disable Checkups v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });
      },
    },

    mounted() {
      this.initTask();
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      ...mapWaitingActions('projectEditor/tasks', {
        createTask: 'saving task',
        updateTask: 'saving task',
        destroyTask: 'destroying task',
      }),

      ...mapWaitingActions('project/project', {
        updateProject: 'updating project',
      }),

      startColorOptionEditing() {
        this.editingColorOptions = true;

        this.$nextTick(() => {
          this.$refs.colorInputRef.focus();
        });
      },

      initTask() {
        if (this.isNew) {
          this.task = {
            ...this.task,
            workPackageId: this.workPackageId,
            teamId: this.teamId,
            order: this.order,
            x: this.x,
            y: this.y,
          };
        } else {
          this.task = cloneDeep(this.taskById(this.taskId));
        }

        this.showCheckupEditor = this.task.checkupItems.length > 0;
      },

      async save() {
        const valid = await this.$validator.validateAll(this.scope);

        if (!valid) return;

        this.prepareCheckupItems();

        if (this.isNew) {
          await this.create();
        } else {
          await this.update();
        }

        this.closeSidePanel();
      },

      prepareCheckupItems() {
        if (this.showCheckupEditor) {
          /**
           * User may have deleted items from the middle of the list,
           * appended new ones, and reordered some. Let's recalculate
           * the items' orders before pushing them to the API.
           */
          this
            .task
            .checkupItems
            .filter(item => !item._destroy)
            .forEach((item, order) => item.order = order); // eslint-disable-line
        } else {
          /**
           * If user has unchecked the checkup editor (i.e. does not
           * want to use a checkup list), destroy all checkupItems.
           */
          this.task.checkupItems.forEach(item => item._destroy = true); // eslint-disable-line
        }

        /**
         * API's strong params expects checkupItemsAttributes since it's
         * nested attribute on task record. However, the attribute itself
         * is named as checkupItems so we need to replace checkupItems key
         * with checkupItemsAttributes so that we can create, update, and
         * delete checkupItems via task record.
         */
        Object.defineProperty(
          this.task, 'checkupItemsAttributes',
          Object.getOwnPropertyDescriptor(this.task, 'checkupItems'),
        ); delete this.task.checkupItems;
      },

      create() {
        const payload = {
          task: {
            ...this.task,
            workPackageId: this.task.workPackageId,
            teamId: this.task.teamId,
            order: this.task.order,
          },
        };

        return this.createTask(payload);
      },

      update() {
        const params = {
          id: this.task.id,
          payload: {
            task: {
              ...this.task,
              workPackageId: this.task.workPackageId,
              teamId: this.task.teamId,
              order: this.task.order,
            },
          },
        };

        return this.updateTask(params);
      },

      async destroy() {
        await this.destroyTask({
          id: this.task.id,
        });

        this.closeSidePanel();
      },

      async createColorOption() {
        const payload = {
          project: {
            colors: [
              ...this.project.colors,
              this.newColorOption,
            ],
          },
        };

        await this.updateProject(payload);

        this.onColorChange({ hex: this.newColorOption.hex });
        this.resetColorOption();
      },

      onColorOptionChange(i) {
        if (!Number.isInteger(i)) return;
        const hex = this.colorOptions[i];
        this.newColorOption.hex = hex;
      },

      resetColorOption() {
        this.newColorOption = {
          name: '',
          hex: '',
        };
        this.editingColorOptions = false;
      },

      async destroyColorOption({ hex }) {
        this.$wait.start(`updating-color-options-${hex}`);

        const newProjectColorOptions = this.project.colors.filter(c => c.hex !== hex);

        const payload = {
          project: {
            colors: newProjectColorOptions,
          },
        };

        await this.updateProject(payload);

        this.$wait.end(`updating-color-options-${hex}`);
      },

      onColorChange({ hex }) {
        if (this.task.hex === hex) {
          this.task.hex = this.DEFAULT_COLOR;
          return;
        }

        this.task.hex = hex;
      },
    },
  };
</script>

<style lang="scss">
.v-color-picker__controls {
  display: none;
}
</style>
