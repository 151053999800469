<template>
  <v-row>
    <v-col
      cols="auto"
    >
      <v-card
        max-width="400px"
      >
        <v-card-title>
          <v-text-field
            v-model="search"
            :label="searchPlaceholder"
            single-line
            hide-details
            outlined
            dense
            autofocus
            clearable
            @click:clear="() => search = ''"
          />
        </v-card-title>

        <v-data-table
          v-model="selectedRows"
          :headers="tableHeaders"
          :items="checkupTemplates"
          :search="search"
          dense
          single-select
          hide-default-footer
          :items-per-page="-1"
          @item-selected="onRowSelect"
        >
          <template #body.prepend>
            <div
              class="relative"
            >
              <v-btn
                dark
                fab
                small
                absolute
                right
                top
                color="info"
                @click="() => editCheckup()"
              >
                <v-icon>
                  add
                </v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item="{ item, isSelected, select }">
            <tr
              :class="{
                'info--text': isSelected,
              }"
              :style="{
                backgroundColor: isSelected ? '#DFE9F5' : '',
                cursor: 'pointer',
              }"
              @click="select(!isSelected)"
            >
              <td class="d-flex align-center">
                {{ item.name }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col
      cols="auto"
    >
      <m-checkup-card
        v-if="showCheckupCard"
        :checkup="selectedCheckup"
        :on-cancel="onCancel"
        :on-submit="onSubmit"
      />
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MCheckupCard from '@/components/checkups/MCheckupCard';

  export default {
    components: {
      MCheckupCard,
    },

    data: () => ({
      search: '',
      selectedRows: [],
      selectedCheckup: null,
    }),

    computed: {
      ...mapGetters({
        checkupTemplates: 'project/checkupTemplates/checkupTemplates',
      }),

      searchPlaceholder() {
        return this.$pgettext('Search placeholder', 'Search for list');
      },

      tableHeaders() {
        return [
          {
            text: this.$pgettext('MCheckupTemplateEditor component table header', 'Checkup list'),
            value: 'name',
          },
        ];
      },

      showCheckupCard() {
        return !!this.selectedCheckup;
      },
    },

    created() {
      this.loadCheckupTemplates();
    },

    methods: {
      ...mapWaitingActions('project/checkupTemplates', {
        loadCheckupTemplates: 'loading templates',
      }),

      onRowSelect({ item: selectedCheckup, value: selected }) {
        this.reset();
        if (!selected) return;
        this.editCheckup(selectedCheckup);
      },

      editCheckup(checkup = null) {
        if (!checkup) this.selectedRows = [];

        this.selectedCheckup = {
          name: '',
          items: [],
          ...checkup,
        };
      },

      reset() {
        this.selectedCheckup = null;
      },

      onCancel() {
        this.selectedRows = [];
        this.reset();
      },

      onSubmit() {
        this.selectedRows = [];
        this.reset();
      },
    },
  };
</script>
