<template>
  <div>
    <!-- start new or create from template -->
    <div
      v-if="!showCheckupItems"
      class="mt-2"
    >
      <v-btn
        dense
        depressed
        x-small
        color="info"
        @click="onCreateNewCheckupList"
      >
        <v-icon
          x-small
          left
        >
          add
        </v-icon>

        <span
          v-translate
          translate-context="MCheckupContainer create new checkup button"
        >
          Create new
        </span>
      </v-btn>

      <span
        v-translate
        translate-context="MCheckupContainer component content"
        class="mx-4 font-weight-bold caption-xs text-uppercase"
      >
        or
      </span>

      <v-menu
        v-model="templateMenu"
        offset-y
      >
        <template #activator="{ on, attrs, value: opened }">
          <v-btn
            x-small
            outlined
            color="info darken-2"
            v-bind="attrs"
            v-on="on"
          >
            <span
              v-translate
              translate-context="MCheckupContainer choose from template button"
            >
              Choose from a template
            </span>

            <v-icon
              small
              class="ml-1"
            >
              keyboard_arrow_down
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list
            dense
          >
            <v-list-item
              @click="openCheckupTemplateDialog"
            >
              <v-list-item-action
                class="mr-3"
              >
                <v-icon
                  small
                >
                  edit
                </v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  <span
                    v-translate
                    translate-context="MCheckupContainer component content"
                  >
                    Manage templates
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item
              v-if="$wait.is('loading templates')"
            >
              <v-list-item-content>
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                  class="mt-2"
                />
              </v-list-item-content>
            </v-list-item>

            <template
              v-else-if="checkupTemplates.length > 0"
            >
              <v-list-item
                v-for="(template, i) in checkupTemplates"
                :key="i"
                @click="() => checkupItemsFromTemplate(template)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ template.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              v-else
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text--secondary font-italic text-center mt-2 caption"
                >
                  <span
                    v-translate
                    translate-context="MCheckupContainer component content"
                  >
                    No templates found
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>

    <!-- checkup list -->
    <div
      v-else
      class="mt-4"
    >
      <m-checkup-list-editor
        :checkup-items="task.checkupItems"
        soft-remove
      />
    </div>

    <v-dialog
      v-model="checkupTemplateDialog"
      fullscreen
      scrollable
    >
      <v-card
        tile
      >
        <v-card-title
          class="d-flex justify-space-between backgroundAccent lighten-2"
        >
          <div
            v-translate
            translate-context="MCheckupContainer component content"
          >
            Manage checkup list templates
          </div>

          <v-btn
            icon
            @click="closeCheckupTemplateEditor"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text
          class="pt-4 backgroundAccent lighten-1"
        >
          <m-checkup-template-editor
            v-if="checkupTemplateDialog"
          />
        </v-card-text>

        <v-card-actions
          class="backgroundAccent lighten-2"
        >
          <v-spacer />

          <v-btn
            color="secondary"
            class="px-8"
            dark
            @click="closeCheckupTemplateEditor"
          >
            <span
              v-translate
              translate-context="Done button"
            >
              Done
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import { mapGetters } from 'vuex';
  import MCheckupTemplateEditor from '@/components/checkups/MCheckupTemplateEditor';
  import MCheckupListEditor from '@/components/checkups/MCheckupListEditor';

  export default {
    components: {
      MCheckupListEditor,
      MCheckupTemplateEditor,
    },

    props: {
      task: {
        type: Object,
        required: true,
      },
    },

    data: vm => ({
      checkupTemplateDialog: false,
      templateMenu: false,
      showCheckupItems: vm.task.checkupItems.length > 0,
    }),

    computed: {
      ...mapGetters({
        checkupTemplates: 'project/checkupTemplates/checkupTemplates',
      }),
    },

    watch: {
      /**
       * Loads checkup templates on menu open.
       *
       * @param opened
       */
      templateMenu(opened) {
        if (!opened) return;
        this.loadCheckupTemplates();
      },
    },

    methods: {
      ...mapWaitingActions('project/checkupTemplates', {
        loadCheckupTemplates: 'loading templates',
      }),

      /**
       * This custom ESC binding was done to make the dialog's
       * and sidePanel's closing behavior work as intendend.
       * @param evt
       */
      closeOnEsc(evt) {
        let isEscape = false;
        if ('key' in evt) {
          isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
        } else {
          isEscape = (evt.keyCode === 27);
        }

        if (isEscape) {
          evt.stopPropagation();
          this.closeCheckupTemplateEditor();
        }
      },

      openCheckupTemplateDialog() {
        this.checkupTemplateDialog = true;

        /**
         * The true argument is useCapture which is needed to prevent
         * from sidePanel closing when closing the template editor via
         * ESC. Kinda hack but what can you do.
         */
        document.addEventListener('keydown', this.closeOnEsc, true);

        this.$mixpanel.trackEvent('Open Checkup Template Editor v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });
      },

      closeCheckupTemplateEditor() {
        this.checkupTemplateDialog = false;
        document.removeEventListener('keydown', this.closeOnEsc, true);

        this.$mixpanel.trackEvent('Close Checkup Template Editor v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });
      },

      checkupItemsFromTemplate(template) {
        this.task.checkupItems = [...template.items];
        this.showCheckupItems = true;

        this.$mixpanel.trackEvent('Build Checkups from Template v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });
      },

      onCreateNewCheckupList() {
        this.showCheckupItems = true;

        this.$mixpanel.trackEvent('Build New Checkups v1', {
          projectId: this.$projectId,
          userId: this.$currentUser.id,
        });
      },
    },
  };
</script>
